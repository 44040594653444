export default {
  isEnvironmentVisible: false,
  isBrandingVisible: false,
  isWidgetMaterialVisible: false,
  isGenerationCrmPlan3dResultHighVisible: false,
  isCustomersVisible: false,
  isDatasetSelectionPlansVisible: false,
  isCanGetPlansToWorkVisible: false,
  isHasPanoramaCheckboxVisible: false,
  isBetaTestingCheckboxVisible: false,
  isWaitDurationVisible: false,
  isWidgetLinkConstructorVisible: false,
  isSamePlansAiVisible: false,
  isPlanDescriptionAndRendersVisible: false,
  isNewPlanOptionsVisible: false,

};
